// Imports
import plugin0 from '__react_static_root__/node_modules/react-static-plugin-reach-router/browser.api.js'

// Plugins
const plugins = [{
        location: "__react_static_root__/node_modules/react-static-plugin-reach-router",
        plugins: [],
        hooks: plugin0({})
      },
{
        location: "__react_static_root__/node_modules/react-static-plugin-styled-components",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/plugins/checkHtmlOutput",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/plugins/checkRelativeLinks",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/plugins/createCanonical",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/plugins/uploadToES",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/plugins/buildSitemap",
        plugins: [],
        hooks: {}
      },
{
        location: "__react_static_root__/",
        plugins: [],
        hooks: {}
      }]

// Export em!
export default plugins